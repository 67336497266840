import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"


import { Col, Image, Media } from "react-bootstrap"

import downloadFile from '../documentos/precios-constructores-reigas.pdf' 

import TuberiaFerreteriaImage from "../images/reigas_imgs_ferrre.png"

const IndexPage = () => (
  <Layout>
    <SEO title="Precios especiales para la industria de la construcción" />
    <Col>
      <h1><strong>Precios especiales</strong> para la industria de la construcción</h1>
      <br />
      <div className="productItem">
        <Media>
          <Image
            // width={150}
            height={150}
            className="mr-3"
            src={TuberiaFerreteriaImage}
            alt="Tuberia y material de"
          />
          <Media.Body>
            <h2>Catálogo actualizado de productos: </h2>
            <span><strong>Actualización:</strong> 14 de julio 2022</span>
            <ul>
              <li>Malla NervoMetal para tumbado.</li>
              <li>Cemento</li>
              <li>
                Calefones
              </li>
              <li>
                Tubería y accesorios
              </li>

              <li>
                Mucho más.
              </li>
            </ul>
            Consulte nuestro{" "}
            <a
              href={downloadFile}
              download={true}
            >
              <b>Catálogo para la industria de la construcción</b>
            </a>
          </Media.Body>
        </Media>
      </div>
      <hr />

      <br />
      <h2>Más Información</h2>

      <p>
        Consulte nuestro{" "}
        <a
          href="https://drive.google.com/open?id=1dvRkpmtyF3xu7ZWaSfUAv9hn2uwCDJJN"
          target="blank"
        >
          <b>Catálogo de Pernos, Tuercas y Arandelas</b>
        </a>{" "}
        y nuestro{" "}
        <a
          href="https://drive.google.com/open?id=176Fj4sCMPz8_nLa-k4503148jJL8fsyh"
          target="blank"
        >
          <b>Catálogo de Tacos, Tirafondos y Tornillos.</b>
        </a>
        .
      </p>
      <p>
        También puede revisar nuestro{" "}
        <a
          href="https://drive.google.com/open?id=11yYtqHOatXqN6mUY5jWUN7tk1OFrpaeG"
          target="blank"
        >
          <b>Catálogo completo de Representaciones Reinoso</b>
        </a>
        , o visitar nuestra sala de exhibición. Póngase en contacto con
        nosotros, gustosos en Atenderle.{" "}
      </p>

      <p>
        <ul>
           
          <li>
            <b>Telf:</b> +593 07-4076831
          </li>
          <li>
            <b>Cel:</b> +593 099 537 1857
          </li>
        </ul>
      </p>
    </Col>
  </Layout>
)

export default IndexPage
